const React = require("react");

const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Label = require("./../../Label.jsx");

module.exports = React.createClass({
  displayName: "ExtensionSeriesHeader",
  shouldComponentUpdate: function (nextProps, nextState) {
    return false;
  },
  render: function () {
    const { showOnlyIf } = this.props;
    if (!showOnlyIf) {
      return <span />;
    }

    return (
      <FieldGroup className="maxcols-8 flush-top flush-left">
        <Field className="maxcols-2"></Field>
        <Field className="maxcols-3 emphasized">
          <Label text="Starts" />
        </Field>
        <Field className="maxcols-3 emphasized">
          <Label text="Stops" />
        </Field>
      </FieldGroup>
    );
  },
});

import { str } from "./basic.js";
import { date } from "./date_and_time.js";

import validator from "./third-party/validator.js";
const mathjs = require("mathjs");
require("sugar-date");

import * as ui from "./UI.js";

var Constants = {
  ImageExtensions: ["gif", "jpg", "jpeg", "png", "webp"],
};

const Tests = {
  blank: (raw) => {
    const { remove_directionals } = str;
    return remove_directionals((raw ?? "").toString()).trim() === "";
  },
  any_text: (raw) => !Tests.blank(raw),

  has_key: (obj, key) => {
    if (obj === undefined) {
      return false;
    }
    return obj[key] !== undefined;
  },
  lacks_key: (obj, key) => {
    return !Tests.has_key(obj, key);
  },
  has_maker: (key) => {
    return (obj) => Tests.has_key(obj, key);
  },
  lacks_maker: (key) => {
    return (obj) => Tests.lacks_key(obj, key);
  },
  has_and_lacks_maker: (key) => {
    const obj = {};
    obj[`has_${key}`] = Tests.has_maker(key);
    obj[`lacks_${key}`] = Tests.lacks_maker(key);
    return obj;
  },

  already_present: (item, listing) => {
    return listing.find((x) => x == item) !== undefined;
  },

  capitalized: (raw) => {
    const first = raw.slice(0, 1);
    return raw.toUpperCase() !== raw && first === first.toUpperCase();
  },
};

Tests.address = {
  zip: (raw) => {
    if (raw == undefined || raw == "") {
      return true;
    }
    const val = str.remove_directionals(raw);
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val);
  },
};

Tests.number = {
  general: (raw) => {
    if (raw == undefined || raw == "") {
      return true;
    }
    const val = str.remove_directionals(raw);
    return val.toString().match(/^-{0,1}\d*\.{0,1}\d*$/);
  },
  digits: (raw) => {
    if (raw == undefined || raw == "") {
      return true;
    }
    const val = str.remove_directionals(raw);
    return val.toString().match(/^\d+$/);
  },
  integer: (raw) => {
    if (raw == undefined || raw == "") {
      return true;
    }
    const val = str.remove_directionals(raw);
    const test_num = parseInt(val, 10).toString();
    return val.toString() == test_num;
  },
  non_negative: (raw) => {
    if (raw == undefined || raw == "") {
      return true;
    }
    const val = str.remove_directionals(raw);
    return Tests.number.general(val) && val.toString().indexOf("-") === -1;
  },
};

Tests.date = {
  blank: Tests.blank,
  cleanup: (raw) => {
    const val = str.remove_directionals(raw);
    const dt = new Date(val);
    if (isNaN(dt.getFullYear())) {
      return undefined;
    }
    return date.to_stamp(dt);
  },
  as_stamp: (raw) => {
    if (typeof raw === "undefined") {
      return undefined;
    }
    if (typeof raw === "number") {
      return raw;
    }
    if (typeof raw === "object") {
      return date.to_stamp(raw);
    }
    if (typeof raw === "string") {
      return Tests.date.cleanup(raw);
    }
  },
  valid: (raw) => {
    const { blank, as_stamp } = Tests.date;
    return blank(raw) || as_stamp(raw) !== undefined;
  },
  given: (raw) => {
    const { blank, valid } = Tests.date;
    return !blank(raw) && valid(raw);
  },
  after: (raw, min_date) => {
    const { blank, valid, as_stamp } = Tests.date;
    return blank(raw) || (valid(raw) && as_stamp(min_date) <= as_stamp(raw));
  },
  after_or_is: (raw, min_date) => {
    const { blank, valid, as_stamp } = Tests.date;
    return blank(raw) || (valid(raw) && as_stamp(min_date) <= as_stamp(raw));
  },
  before: (raw, max_date) => {
    const { blank, valid, as_stamp } = Tests.date;
    return blank(raw) || (valid(raw) && as_stamp(raw) <= as_stamp(max_date));
  },
  before_or_is: (raw, max_date) => {
    const { blank, valid, as_stamp } = Tests.date;
    return blank(raw) || (valid(raw) && as_stamp(raw) <= as_stamp(max_date));
  },
  between: (raw, min_date, max_date) => {
    const { blank, valid, after, before } = Tests.date;
    return (
      blank(raw) ||
      (valid(raw) && after(raw, min_date) && before(raw, max_date))
    );
  },
  past: (raw) => {
    return Tests.date.before(raw, new Date());
  },
  future: (raw) => {
    return Tests.date.after(raw, new Date());
  },
  remotely_plausible: (raw) => {
    const { blank, between } = Tests.date;
    return !blank(raw) && between(raw, "1/1/1990", "12/31/2099");
  },
  plausible: (raw) => {
    const { blank, between } = Tests.date;
    const { add, today } = date;
    return (
      blank(raw) || between(raw, add.years(today(), -1), add.years(today(), 1))
    );
  },
  reasonable: (raw) => {
    const { blank, between } = Tests.date;
    const { add, today } = date;
    return (
      blank(raw) || between(raw, add.days(today(), -10), add.days(today(), 10))
    );
  },

  check: {
    date_range: (start_date, stop_date) => {
      const { blank, valid, as_stamp } = Tests.date;

      if (blank(start_date) || blank(stop_date)) {
        return "Make sure dates are set.";
      }
      if (!(valid(start_date) && valid(stop_date))) {
        return "Make sure dates are valid.";
      }
      if (!(as_stamp(start_date) < as_stamp(stop_date))) {
        return "Make sure stop date is later than start date.";
      }
      return undefined;
    },
  },
};

Tests.email = (raw) => {
  if (raw === undefined || raw == "") {
    return true;
  }
  const val = str.remove_directionals(raw);
  return validator.isEmail(val);
};

Tests.url = {
  is_image: (raw) => {
    if (raw === undefined || raw == "") {
      return true;
    }
    const val = str.remove_directionals(raw);
    if (!Tests.url.plausible(val)) {
      return false;
    }
    var path = val.split("/");
    var filename = path[path.length - 1].toLowerCase();
    if (filename.indexOf(".") == -1) {
      return false;
    }
    var file_parts = filename.split(".");
    var ext = file_parts[file_parts.length - 1];
    return Constants.ImageExtensions.indexOf(ext) > -1;
  },
  plausible: (raw) => {
    if (raw === undefined || raw == "") {
      return true;
    }
    const val = str.remove_directionals(raw);
    return validator.isURL(val);
  },
};

var setup_form_validations = () => {
  return;
  var Validations = [
    {
      selector: 'input[type="date"]',
      test: (field) => {
        if (!Tests.date.valid(field.val())) {
          return false;
        }
        var value = Date.create(field.val());
        var minimum = Date.create(field.attr("min") || value);
        var maximum = Date.create(field.attr("max") || value);
        return (
          Tests.date.after(field.val(), minimum) &&
          Tests.date.before(field.val(), maximum)
        );
      },
      explain: (field) => {
        var format = (d) => {
          return d.format("{MM}/{dd}/{yyyy}");
        };
        var answer = "Please enter a valid date";
        if (field.val() !== "") {
          if (!new Date(field.val()).isValid()) {
            answer += ".";
            return answer;
          }
        }
        if (field.attr("min")) {
          var minimum = Date.create(field.attr("min"));
          answer += " no earlier than " + format(minimum);
        }
        if (field.attr("max")) {
          if (field.attr("min")) {
            answer += " and";
          }
          var maximum = Date.create(field.attr("max"));
          answer += " no later than " + format(maximum);
        }
        return answer;
      },
    },
    {
      selector: '[aria-required="true"]',
      test: (field) => {
        return Tests.any_text(field.val());
      },
      explain: (field) => {
        return field.attr("title") || "Please fill out this field.";
      },
    },
  ];

  $("form.Validated").submit((e) => {
    var valid = true;
    var here = $(e.currentTarget);
    var fields = here.find(":input").get();

    for (var f = 0; f < fields.length; f++) {
      var field = $(fields[f]);
      for (var i = 0; i < Validations.length; i++) {
        var validator = Validations[i];
        if (field.is(validator.selector)) {
          var checks_out = validator.test(field);
          field.attr("aria-invalid", checks_out ? "false" : "true");
          if (!checks_out) {
            valid = false;
            e.preventDefault();
            var error_message = validator.explain(field);
            ui.alert(error_message, field);
            field.focus();
          }
        }
      }
      if (field.is(":invalid")) {
        valid = false;
        e.preventDefault();
        ui.alert("Please enter valid information for this field", field);
        field.focus();
      }
      if (!valid) {
        here.addClass("Invalid");
        break;
      }
    }
  });
};

module.exports = {
  Tests,
  setup_form_validations,
};

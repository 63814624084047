module.exports = ({
    maxSize = 1 * 1024 * 1024, //...max file size in bytes
    mimeTypes = [], //................allowed file types; unrestricted if empty
    allowedText = "", //..............explicit description of allowed file types/size/etc.

    newFiles = [], //.................new file uploads, if any

    updateAction, //................action to select or clear file info
    warnAction //..................action to provide errors/advisory with
}) => {
    module.exports.displayName = "FileUpload";
    const new_files = [...newFiles];
    const newest_file =
        new_files.length === 0 ? {} : new_files[new_files.length - 1];

    const handle_select = e => {
        const file_info = e.target.files[0];
        $(e.target).val("");
        if (validate_file(file_info)) {
            const name = file_info.name;
            const ext = name
                .split(".")
                .slice(-1)[0]
                .toLowerCase();
            new_files.push({
                active: true,
                file: {
                    name: name,
                    ext: ext,
                    info: file_info
                }
            });
            if (updateAction === undefined) {
                console.log("No update action set.");
                return;
            }
            updateAction(new_files);
        }
    };

    const validate_file = info => {
        if (info === undefined) {
            return false;
        }
        if (mimeTypes.length > 0) {
            console.log({"mime":info.type});
            const valid = mimeTypes.some(mime => {
                return info.type.startsWith(mime);
            });
            if (!valid) {
                const use_text =
                    allowedText === ""
                        ? "Please select one of the following: " +
                          mimeTypes.join(", ") +
                          "."
                        : allowedText;
                warnAction("Invalid file type.  " + allowedText);
                return false;
            }
        }
        if (maxSize) {
            if (info.size > maxSize) {
                const size = parseInt((maxSize * 1.0) / 1024.0, 10);
                warnAction(
                    "File is larger than limit of " +
                        size.toString() +
                        " kilobytes.  " +
                        "Please pick another file."
                );
                return false;
            }
        }
        return true;
    };

    return (
        <div>
            <form id="upload-file-form" encType="multipart/form-data">
                <input
                    id="upload-file"
                    type="file"
                    className="field.entry field.entry-file.file FileSelect"
                    onChange={handle_select}
                />
                <div>
                    <div className="item.stub no-pad-left">
                        <label
                            htmlFor="upload-file"
                            className="little-button VisibleFileSelect">
                            Select File
                        </label>
                    </div>
                </div>
            </form>
        </div>
    );
};

import { coalesce, str } from "./basic.js";

const mathjs = require("mathjs");

const integer_formatter = new Intl.NumberFormat("en-US", { style: "decimal" });

const us_money_formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const numbers = {
  from_obj: (obj) => {
    if (obj.mathjs === "Fraction") {
      return mathjs.fraction(obj);
    }
    if (obj.mathjs === "BigNumber") {
      return mathjs.bignumber(obj);
    }
    return obj;
  },
  to_obj: (val) => {
    return val.toJSON();
  },

  as_percent: (s) => {
    const num = coalesce(s, "0");
    return mathjs.fraction(num).div(100);
  },
  as_percent_or_null: (s) => {
    if (str.blank(s)) {
      return null;
    }
    return numbers.as_percent(s);
  },
  rate_format: (rate, digits = 5) => {
    if (rate === undefined) {
      return numbers.rate_format(0);
    }
    const { bignumber, fraction, format } = mathjs;
    return format(bignumber(fraction(rate).mul(100)), digits);
  },
  rate_format_or_blank: (rate, digits = 5) => {
    if (rate === undefined || rate === null) {
      return "";
    }
    if (rate?.strip !== undefined && rate.strip() == "") {
      return "";
    }
    return numbers.rate_format(rate, digits);
  },
  percent_format: (rate, digits = 5) => {
    const result = numbers.rate_format(rate, digits);
    return result == "" ? result : result + "%";
  },
  pluralize: (number, singular, plural) => {
    return number === 1 ? singular : plural;
  },
  int_format: (number) => {
    return integer_formatter.format(number);
  },
  digits: (frac, digits) => {
    if (frac === undefined) {
      return "";
    }
    const decimal_formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
    const { format, number, fraction } = mathjs;
    return decimal_formatter.format(number(fraction(frac)));
  },
};

const money = {
  format: (obj) => {
    // May have to make this more precise
    if (obj === undefined) {
      return "";
    }
    return us_money_formatter.format(numbers.from_obj(obj));
  },
  number_format: (obj) => {
    return money.format(obj).replace(/[$,]+/g, "");
  },

  from_str: (s) => {
    const value = coalesce(s, "").trim();
    if (value === "") {
      return mathjs.fraction(0);
    }
    return mathjs.fraction(value);
  },

  from_obj: (obj) => {
    if (obj === undefined) {
      return undefined;
    }
    return numbers.from_obj(obj);
  },
  to_obj: (s) => {
    if ((s || "") === "") {
      return mathjs.fraction(0);
    }
    return mathjs.fraction(s);
    //const cents = Math.round(parseFloat(s) * 100);
    //return money.from_cents(cents);
  },

  to_cents: (obj) => {
    const value = mathjs.bignumber(mathjs.fraction(obj));
    return value.mul(100).toNumber();
  },
  from_cents: (raw_cents) => {
    const cents = Math.round(raw_cents);
    return mathjs.fraction(cents, 100);
  },
};

module.exports = {
  integer_formatter,
  money,
  numbers,
  us_money_formatter,
};

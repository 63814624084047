module.exports = ({
  data,
  className,
  label = undefined,
  showOnlyIf = true,
}) => {
  module.exports.displayName = "Dump";
  if (!showOnlyIf) {
    return <span />;
  }
  const classes = lib.handle_classes(className, "json-dump");

  return (
    <pre className={classes}>
      {label ? <b>{label}: </b> : <span />}
      {JSON.stringify(data, null, 4)}
    </pre>
  );
};

import * as basic from "./basic.js";
import { time } from "./date_and_time.js";
import { Tests } from "./validation.js";

const mathjs = require("mathjs");

const format_ad_text = (raw) => {
  const IndentLevel = 4;
  const result = [];
  const get_indent = (line) => {
    let text = line.toString();
    let length = 0;
    while (text.startsWith(" ")) {
      text = text.slice(1);
      length += 1;
    }
    return length;
  };
  const indents = {};
  raw.split("\n").forEach((line) => {
    const indent = get_indent(line);
    indents[indent] = Math.round(indent / IndentLevel) * IndentLevel;
  });

  const unique_indents = basic.iter.unique(
    basic.obj.values(indents).sort((a, b) => {
      return a - b;
    })
  );
  const adjusted = {};
  unique_indents.forEach((indent, i) => {
    adjusted[indent] = i * 4;
  });
  const lines = raw.split("\n").map((line) => {
    const indent = adjusted[indents[get_indent(line)]];
    return Array(indent + 1).join(" ") + line.trim();
  });
  return lines.join("\n");
};

const territories = {
  keys: (starter_text, territory) => {
    const keys = {};
    const ids = {};
    const values = basic.obj.values;
    const make_key = (items) => {
      const parts = items.map((x) => x.toString());
      const key = starter_text + "-" + parts.join("-");
      keys[key] = "";
    };
    Object.keys(territory.companies).forEach((c) => {
      const at_company = ["companies", c];
      if (!territory.all_companies) {
        make_key(at_company);
      }
      const company = territory.companies[c];
      Object.keys(company.states).forEach((s) => {
        const at_state = [...at_company, "states", s];
        if (!company.all_states) {
          make_key(at_state);
        }
        const state = company.states[s];
        Object.keys(state.markets).forEach((m) => {
          const at_market = [...at_state, "markets", m];
          if (!state.all_markets) {
            make_key(at_market);
          }
        });
      });
    });
    return keys;
  },
  projects: (territory) => {
    const { values } = basic.obj;
    const flatten = (a, b) => {
      return a.concat(b);
    };
    const for_all = values(territory.companies).map((company) => {
      const for_company = values(company.states).map((state) => {
        const for_state = values(state.markets).map((market) =>
          values(market.projects)
        );
        if (for_state.length > 0) {
          return for_state.reduce(flatten);
        }
        return [];
      });
      if (for_company.length > 0) {
        return for_company.reduce(flatten);
      }
      return [];
    });
    if (for_all.length > 0) {
      return for_all.reduce(flatten);
    }
    return [];
  },
  projects_for: (territory, start_at, stop_at) => {
    const base = territories.projects(territory);
    const { as_stamp } = time;
    const not_blank = (x, alt) => (x === "" ? alt : x ?? alt);
    const open_past = as_stamp("1/1/1980");
    const open_future = as_stamp("12/31/2200");
    const use = {
      start_at: not_blank(start_at, open_past),
      stop_at: not_blank(stop_at, open_future),
    };
    const result = base.filter((p) => {
      const starts = not_blank(p.start_date, open_past);
      const stops = not_blank(p.stop_date, open_future);
      const is_between = (item, range_begin, range_end) => {
        return range_begin <= item && item <= range_end;
      };
      return (
        is_between(starts, use.start_at, use.stop_at) ||
        is_between(stops, use.start_at, use.stop_at) ||
        is_between(use.start_at, starts, stops)
      );
    });
    result.sort((a, b) => basic.cmp(a.name, b.name));
    console.log({ result });
    return result;
  },
  count_projects: (territory) => {
    return territories.just_projects(territory).length;
  },
  validate: (territory, add_error) => {
    const today = time.today();

    const allowable_date = (dt) => {
      const { valid, after, before } = Tests.date;
      if (dt !== undefined) {
        if (!valid(dt)) {
          return { valid: false, message: `'${dt}' is invalid` };
        }
        /* if (before(dt, today)) {
         *     return {valid: false, message: `'${dt}' is in past`};
         * } */
      }
      return { valid: true, message: "" };
    };
    const handle_any_error = (project, field, field_name) => {
      const { name } = project;
      const id = project.id.toString();
      const date_test = allowable_date(project[field]);
      if (!date_test.valid) {
        const { message } = date_test;
        add_error(`${id}-${field}`, `Project ${field_name} ${message}`);
      }
    };

    if (territory.companies === undefined) {
      add_error("territory", "Please enter territory information.");
      return;
    }

    Object.keys(territory.companies).forEach((c) => {
      const company = territory.companies[c];
      Object.keys(company.states).forEach((s) => {
        const state = company.states[s];
        Object.keys(state.markets).forEach((m) => {
          const market = state.markets[m];
          Object.keys(market.projects).forEach((p) => {
            const project = market.projects[p];
            handle_any_error(project, "stop_date", "stop date");
            handle_any_error(project, "start_date", "start date");
          });
        });
      });
    });
  },
};

const total_monthly_income = (people) => {
  const { sum, fraction } = mathjs;
  const monthly_incomes = people
    .filter((person) => person.deleted === undefined)
    .map((person) => person.demographics.monthly_income_prequal)
    .filter((mip) => mip !== undefined)
    .map((mip) => fraction(mip));
  return sum([fraction("0.00"), ...monthly_incomes]);
};

const coord_num = (val) => {
  if (val === undefined) {
    return undefined;
  }
  return mathjs.string(mathjs.fraction(val));
};

module.exports = {
  format_ad_text,
  territories,
  total_monthly_income,
  coord_num,
};

const AddressEntryDisplay = require("./AddressEntryDisplay.jsx");

module.exports = ({history, className}) => {
    module.exports.displayName = "AddressHistoryDisplay";
    if (lib.coalesce(history, []).length === 0) {
        return <br />;
    }
    const classes = lib.handle_classes(className, "address-history-list");

    return (
        <ul className={classes}>
            {history.map(entry => {
                const key = "address-history-entry" + entry.id.toString();
                return (
                    <li key={key} className="address-history-item">
                        <AddressEntryDisplay entry={entry} />
                        <br />
                    </li>
                );
            })}
        </ul>
    );
};

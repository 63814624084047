const Quote = require("./../Quote.jsx");
const Time = require("./../Time.jsx");

const EmployeeMention = require("./EmployeeMention.jsx");

module.exports = ({note}) => {
    module.exports.displayName = "NoteDisplay";
    const {recorded, author, content, original_raw_content} = note;
    const paragraphs = content
        .split("\n")
        .map(p => p.trim())
        .filter(p => p !== "");
    return (
        <div className="note middle">
            <Time
                className="header-time text flush-left"
                timestamp={recorded}
                formatter={lib.time.format.normal}
            />
            <cite>
                <EmployeeMention className="text flush-left" person={author} />
            </cite>
            <Quote className="note.quote" text={content} id={note.id} />

            {true || original_raw_content === undefined ? (
                <span />
            ) : (
                <div style={{outline: "solid 1px red"}}>
                    <div
                        dangerouslySetInnerHTML={{__html: original_raw_content}}
                    />
                </div>
            )}
        </div>
    );
};

module.exports = ({
    entry,
    label,
    columns,
    className,
    labelClassName,
    entryClassName,
    showOnlyIf = true
}) => {
    module.exports.displayName = "ViewField";
    if (!showOnlyIf) {
        return <span />;
    }

    const annotate_class = (base_class, col_key, sub_class = "") => {
        const cols = (columns || {})[col_key];
        const columns_class =
            cols !== undefined
                ? "maxcols-" +
                  (col_key === "all" ? "" : "padded-") +
                  cols.toString()
                : "";
        return lib.handle_classes(
            [base_class || "", columns_class].join(" ").trim(),
            "display-field" + sub_class
        );
    };
    return (
        <div className={annotate_class(className, "all")}>
            <div className={annotate_class(labelClassName, "label", ".label")}>
                {label}
            </div>
            <div className={annotate_class(entryClassName, "entry", ".entry")}>
                {entry}
            </div>
        </div>
    );
};

const TextInput = require("./TextInput.jsx");

module.exports = ({
  id,
  name,
  amount,
  updateAction,
  format = lib.money.format,
  error,
  warning = undefined,
  className = "",
  disabled = undefined,
}) => {
  module.exports.displayName = "MoneyEntry";

  let classes = className.trim();
  if (className.indexOf("maxcols") === -1) {
    classes = lib.handle_classes(classes, "maxcols-padded-4");
  }
  ["field.entry", "numeric"].forEach((name) => {
    classes = lib.handle_classes(classes, name);
  });

  const nice_amount = format(amount);
  const updater = (val) => {
    const clean_val = val.replace("$", "").replace(/[,]+/g, "");
    const as_money = lib.money.to_obj(clean_val);
    updateAction(as_money);
  };

  return (
    <TextInput
      id={id ?? name}
      name={name ?? id}
      className={classes}
      mask={standard.masks.money}
      value={nice_amount}
      updateOn="blur"
      updateAction={updater}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  );
};

const React = require("react");

module.exports = React.createClass({
  displayName: "TextInput",
  getInitialState: function () {
    return this.props;
  },

  /* shouldComponentUpdate(nextProps) {
   *     return JSON.stringify(nextProps) !== JSON.stringify(this.props);
   * },*/

  componentWillReceiveProps: function (new_props) {
    this.setState(new_props);
  },

  maskAccepts: function (value) {
    const { mask } = this.props;
    if (typeof mask === "function") {
      return mask(value);
    }
    const regex = typeof mask === "string" ? new RegExp(mask) : mask;
    return regex.test(value);
  },
  applyExternalUpdater: function (e) {
    standard.handlers.basic_change(this.props)(e);
  },

  shouldUpdateOn: function (name) {
    const options = lib
      .coalesce(this.props.updateOn, "change")
      .toLowerCase()
      .split(" ");
    return options.indexOf(name.toLowerCase()) !== -1;
  },
  handleChange: function (e) {
    const value = e.target.value.toString();
    if (this.props.mask !== undefined) {
      if (!this.maskAccepts(value)) {
        e.preventDefault();
        return;
      }
    }
    if (this.shouldUpdateOn("change")) {
      this.applyExternalUpdater(e);
    }
    this.setState({ value: value });
  },
  handleKeyDown: function (e) {
    // to handle updating before the submit by a surrounding form
    if (e.key == "Enter" && this.shouldUpdateOn("blur")) {
      document.activeElement.blur();
    }
  },
  handleBlur: function (e) {
    if (this.shouldUpdateOn("blur")) {
      this.applyExternalUpdater(e);
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  },

  render: function () {
    if (this.props.showOnlyIf !== undefined) {
      if (!this.props.showOnlyIf) {
        return <span />;
      }
    }

    const any_error = this.props.error !== undefined;
    const any_warning = this.props.warning !== undefined;
    const classes =
      lib.handle_classes(this.state.className, "field.entry") +
      (any_warning ? " warning-notice" : "");
    const the_title = lib.coalesce(
      this.props.error,
      this.props.warning,
      this.props.title
    );

    if (this.props.area) {
      return (
        <textarea
          id={this.state.id ?? this.state.name}
          name={this.state.name}
          type="text"
          className={classes}
          autoFocus={this.props.autoFocus}
          tabIndex={this.props.tabIndex}
          aria-invalid={any_error}
          title={the_title}
          placeholder={this.props.placeholder}
          maxLength={this.state.maxLength}
          value={this.state.value || ""}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.props.onFocus}
          onBlur={this.handleBlur}
          disabled={this.props.disabled}
          style={this.props.style}
        />
      );
    }
    return (
      <input
        id={this.state.id ?? this.state.name}
        name={this.state.name}
        type="text"
        className={classes}
        autoFocus={this.props.autoFocus}
        tabIndex={this.props.tabIndex}
        aria-invalid={any_error}
        title={the_title}
        placeholder={this.props.placeholder}
        maxLength={this.state.maxLength}
        value={this.state.value || ""}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
        style={this.props.style}
      />
    );
  },
});

//import $ from "./third-party/jquery-1.12.3.min.js";

export var setup_ui_enhancements = () => {
  (() => {
    $("#Login-Bounce").val(
      ["pathname", "search", "hash"].map((key) => window.location[key]).join("")
    );

    $("#Browse-Controls").submit((e) => {
      var invalids = $("#Browse-Controls").find(":invalid, .Invalid");
      if (invalids.length > 0) {
        return;
      }
      if ($("#FilterBox").is(":visible")) {
        $('[data-toggle-id="FilterBox"]').click();
      }
      if ($("#SortBox").is(":visible")) {
        $('[data-toggle-id="SortBox"]').click();
      }
    });
  })();

  (() => {
    $(".Confirm").click((e) => {
      var here = $(e.currentTarget);
      var message =
        here.data("confirm-message") || "Are you sure you want to do this?";
      if (!confirm(message)) {
        e.preventDefault();
      }
    });
  })();

  (() => {
    $(".Drop").submit((e) => {
      var here = $(e.currentTarget);
      var message =
        here.data("drop-message") || "Are you sure you want to delete this?";
      if (!confirm(message)) {
        e.preventDefault();
      }
    });
  })();

  (() => {
    $(".GoBack").click((e) => {
      e.preventDefault();
      window.history.back();
    });
  })();

  (() => {
    var get_target = (here) => {
      var target_id = here.data("toggle-id");
      if (target_id !== undefined) {
        return $("#" + target_id.replace(/,/g, ", #"));
      }
      return undefined;
    };
    var toggle_sign = (here, target) => {
      var span = here.find("span:first");
      var first_target = $(target[0]);
      if (span !== undefined && target !== undefined) {
        if (can_see(first_target)) {
          span.html(" [-]");
        } else {
          span.html(" [+]");
        }
      }
    };
    var can_see = (target) => {
      return target.is(":visible") && target.height() > 1;
    };
    var dummy = () => {};
    var show = (item, func = null) => {
      item.slideDown(400, func || dummy);
    };
    var hide = (item, func = null) => {
      item.slideUp(400, func || dummy);
    };

    $(".ToToggle").hide();
    $(".Toggle span:first").html(" [+]");
    $(".Toggle")
      .attr("tabindex", "0")
      .keydown(function (e) {
        if (e.which === 13) {
          e.preventDefault();
          $(e.target).click();
        }
      })
      .click((e) => {
        e.preventDefault();
        var here = $(e.currentTarget);
        var target = get_target(here);
        var handle_sign = () => {
          toggle_sign(here, target);
        };

        if (target === undefined) {
          return;
        }
        var first_target = $(target[0]);
        if (can_see(first_target)) {
          hide(target, handle_sign);
        } else {
          show(target, handle_sign);
        }
        toggle_sign(here, target);
      });
    $(".Toggle").map((i, elem) => {
      var here = $(elem);
      var target = get_target(here);
      toggle_sign(here, target);
    });

    $(".ShowOnValue").change((e) => {
      e.preventDefault();
      var here = $(e.currentTarget);
      var target_value = here.data("reveal-value");
      var target_id = here.data("show-id");
      if (target_value === undefined) {
        return;
      }
      if (target_id === undefined) {
        return;
      }
      var target = $("#" + target_id);
      if (target === undefined) {
        return;
      }

      if (here.val() == target_value) {
        show(target);
      } else {
        hide(target);
      }
    });
  })();

  (() => {
    window.addEventListener("beforeunload", (e) => {
      const changed = window.app_editing_changed || false;
      const message =
        "Are you sure you want to leave this page without saving your changes?";
      if (changed) {
        e.returnValue = message;
        return message;
      }
      return undefined;
    });
  })();
};

const TextInput = require("./TextInput.jsx");

module.exports = ({
  id,
  date,
  value,
  updateAction,
  className,
  placeholder,
  autoFocus,
  onBlur,
  onFocus,
  onChange,
  tabIndex,
  error,
  ref,
  warning,
  disabled,
}) => {
  module.exports.displayName = "DateEntry";

  const classes = lib.handle_classes(
    lib.handle_classes(className, "numeric"),
    "maxcols-padded-3"
  );
  const { to_iso, from_iso, format } = lib.date;
  const the_date = lib.coalesce(date, value);
  if (typeof the_date === "number") {
    ui.alert(
      `Error: Date Entry ${
        id === undefined ? "" : ` ${id} `
      } is receiving a timestamp (${the_date})`
    );
    return (
      <div id={id} className={classes}>
        <b>
          <i>Invalid</i>
        </b>
      </div>
    );
  }
  const blank_date = the_date === undefined || the_date === null;
  const nice_date = blank_date ? "" : format.plain.date(from_iso(the_date));

  const updater = (value) => {
    if (updateAction === undefined) {
      return;
    }
    if (tests.date.blank(value)) {
      if (the_date !== undefined) {
        updateAction(undefined);
      }
      return;
    }
    if (!tests.date.valid(value)) {
      return;
    }
    const result = lib.date.parse(value);
    if (result === undefined) {
      updateAction(undefined);
      return;
    }
    updateAction(to_iso(result));
  };

  return (
    <TextInput
      id={id}
      className={classes}
      placeholder={placeholder}
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      value={nice_date}
      updateAction={updater}
      mask={standard.masks.date}
      updateOn="blur"
      onFocus={onFocus}
      error={error}
      warning={warning}
      disabled={disabled}
      ref={ref}
    />
  );
};

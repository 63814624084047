const Anchor = require("./../Anchor.jsx");

module.exports = ({
	extension,
	link,
	inline,
	className
}) => {
	module.exports.displayName = "ExtensionMention";
	
	const id = extension.id.toString();
	const number = "#" + extension.number.toString().trim();
	if (link) {
		const classes = lib.handle_classes(className, "item.link");
		return (
			<Anchor className={classes}
					href={`${standard.URL.extensions.pages}?item=${extension.id}`}>
				{number}
			</Anchor>
		);
	}
	if (inline) {
		return <span className={className}>{number}</span>;
	}
	return <div className={className}>{number}</div>;
}

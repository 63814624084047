const get_name = (person, last_first, default_text = "") => {
    if (person === undefined) {
        return default_text;
    }
    const clean = x => lib.coalesce(x, "").trim();
    const first_name = clean(person.first_name);
    const last_name = clean(person.last_name);
    if (last_name + first_name === "") {
        return default_text;
    }
    return (lib.coalesce(last_first, false)
        ? `${last_name}, ${first_name}`
        : `${first_name} ${last_name}`
    ).trim();
};

const decorate = (text, styling) => {
    const clean_style = lib.coalesce(styling, "").toLowerCase();

    if (clean_style === "italics") {
        return <i>{text}</i>;
    }
    if (clean_style === "parens") {
        return `(${text})`;
    }

    return text;
};

module.exports = ({
    person,
    lastFirst,
    link,
    inline,
    className,
    blankText,
    decoration
}) => {
    module.exports.displayName = "EmployeeMention";

    if (person === undefined || person.id === undefined) {
        if (blankText !== undefined) {
            return blankText;
        }
        return <span />;
    }

    const name = decorate(
        get_name(person, lastFirst, "(No Name Set)"),
        decoration
    );
    const id = person.id.toString();
    if (link) {
        const classes = lib.handle_classes(className, "item.link");
        const url = standard.URL.employees.pages + "?item=" + id;
        return (
            <a className={classes} href={url}>
                {name}
            </a>
        );
    }
    if (inline) {
        return <span className={className}>{name}</span>;
    }
    return <div className={className}>{name}</div>;
};

module.exports = ({ endpoint, store }) => {
  module.exports.displayName = "CSVDownload";

  const target = api.build_api_list_url(endpoint, store) + "&gloss=&csv=";

  return (
    <div>
      <br />
      <div className="item middle maxcols-2 to-center">
        <a className="item.link" href={target} rel="noopener" type="text/csv">
          CSV
        </a>
      </div>
    </div>
  );
};

module.exports = ({people}) => {
    module.exports.displayName = "PeopleNames";

    return (
	<span>
	    {people.map((person, i) => {
		 return (
		     <PersonName person={person}
				 index={i} length={people.length}
				 key={`person-${i}`} />
		 );
	    })}
	</span>
    );
};


const PersonName = ({person, index, length}) => {
    const {first_name, last_name} = person;

    const name = `${first_name} ${last_name}`.trim();
    const last = length - 1;
    const need_and = (length > 1 && index === last);
    const need_comma = (length > 2 && index < last);
    
    return (
	<span>
	    {need_and ? " & " : ""}{name}{need_comma ? ", " : ""}
	</span>
    );
};

const obj = {
  clone: (o) => {
    if (o === undefined) {
      return undefined;
    }
    if (typeof o === "object" && o.getTime !== undefined) {
      return new Date(o.getTime());
    }
    return JSON.parse(JSON.stringify(o));
  },
  clone_only: (o, keys) => {
    if (o === undefined) {
      return undefined;
    }
    const result = {};
    keys.forEach((key) => {
      if (o[key] !== undefined) {
        result[key] = JSON.parse(JSON.stringify(o[key]));
      }
    });
    return result;
  },
  is_empty: (o) => {
    if (o === undefined) {
      return true;
    }
    return Object.keys(o).length === 0;
  },
  length: (o) => {
    if (o === undefined) {
      return 0;
    }
    return Object.keys(o).length;
  },
  not_empty: (o) => {
    return !obj.is_empty(o);
  },
  all_blank: (o, fields) => {
    return fields.every((f) => coalesce(o[f], "").trim() === "");
  },
  update: (o, key, func) => {
    // Impure!
    const result = func(o[key]);
    o[key] = result;
    return result;
  },
  values: (o) => {
    // While Object.values is not well-supported.
    if (o === undefined) {
      return [];
    }
    return Object.keys(o).map((key) => {
      return o[key];
    });
  },
  path_lookup_direct: (o, path) => {
    // Impure!  That's the point.
    if (o === undefined) {
      return;
    }
    if (path.length === 0) {
      return o;
    }
    let pos = o;
    path.slice(0, -1).forEach((step) => {
      if (pos.hasOwnProperty(step)) {
        pos = pos[step];
      } else {
        return undefined;
      }
    });
    const value = pos[path[path.length - 1]];
    return value;
  },
  path_lookup: (o, path) => {
    return obj.path_lookup_direct(o, path);
    //return obj.clone(obj.path_lookup_direct(o, path));
  },
  easy_path: (o, path_or_key) => {
    const path = Array.isArray(path_or_key) ? path_or_key : [path_or_key];
    return obj.path_lookup(o, path);
  },
  path_fails_at: (o, path) => {
    if (o === undefined) {
      return;
    }
    let pos = o;
    for (let i = 0; i < path.length; i++) {
      const step = path[i];
      if (!pos.hasOwnProperty(step)) {
        return i;
      }
      pos = pos[step];
    }
    return -1;
  },
  path_exists: (o, path) => {
    return obj.path_lookup_direct(o, path) !== undefined;
  },

  path_set: (o, path, value) => {
    const position = obj.path_lookup_direct(o, path.slice(0, -1));
    console.log({ o, position, path });
    position[path[path.length - 1]] = value;
  },

  nest_from_path: (path, final = undefined) => {
    const result = {};
    path.reduce((o, key) => {
      return (o[key] = {});
    }, result);
    if (final !== undefined) {
      obj.path_set(result, path, final);
    }
    return result;
  },
};

const iter = {
  coalesce: (...items) => {
    for (let item of items) {
      if (item !== undefined && item !== null) {
        return item;
      }
    }
  },
  coalesce_blank: (...items) => {
    for (let item of items) {
      if (item !== undefined && item.toString().trim !== "") {
        return item;
      }
    }
  },
  unique: (items) => {
    return items.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  },

  is_array: (thing) => {
    return typeof thing === "object" && thing.length !== undefined;
  },
  is_empty_array: (thing) => {
    return iter.is_array(thing) && thing.length === 0;
  },
};

const { coalesce } = iter;

const yes_or_no = (val) => {
  return val === true ? "Yes" : "No";
};

const str = {
  title_case: (words) => {
    const title_word = (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    };
    return words.replace(/\w\S*/g, title_word);
  },
  as: (thing) => {
    return coalesce(thing, "").toString().trim();
  },
  blank: (thing) => {
    return str.as(thing) === "";
  },
  remove_directionals: (raw) => {
    if (raw === undefined) {
      return undefined;
    }
    /*
           IE 11 (and perhaps Edge?) add Unicode LTR characters to form values.
           This removes those and RTL characters.
         */
    return raw
      .toString()
      .split("")
      .filter((c) => {
        const code = c.charCodeAt(0);
        return code !== 8206 && code !== 8207;
      })
      .join("");
  },
};

const text = {
  loose_match: (prospect, target, loose = false) => {
    const up = (s) => {
      return s.toUpperCase();
    };
    if (target.startsWith(" ")) {
      return up(prospect).indexOf(up(target.trim())) !== -1;
    }
    return up(prospect).startsWith(up(target));
  },
};

const cmp = (a, b) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};
const sorters = {
  str: cmp,
  str_no_case: (a, b) => {
    const A = a.toString().toUpperCase();
    const B = b.toString().toUpperCase();
    return cmp(A, B);
  },
  title: (a, b) => {
    const clean = (x) => {
      if (x.toUpperCase().substr(0, 4) == "THE ") {
        return x.substr(4, x.length);
      }
      return x;
    };
    const A = clean(a.toString()).toUpperCase();
    const B = clean(b.toString()).toUpperCase();
    return cmp(A, B);
  },
};

module.exports = {
  cmp,
  coalesce,
  iter,
  obj,
  sorters,
  str,
  text,
  yes_or_no,
};

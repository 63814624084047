const Anchor = require("./../Anchor.jsx");

module.exports = ({
	type,
	link,
	text,
	inline,
	className
}) => {
	module.exports.displayName = "SourceTypeMention";
	const {name, id} = type;
	if (link) {
		const classes = lib.handle_classes(className, "item.link");
		return (
			<Anchor className={classes}
					href={`${standard.URL.source_types.pages}?item=${id}`}>
				{name}
			</Anchor>
		);
	}
	if (inline) {
		return <span className={className}>{name}</span>;
	}
	return <div className={className}>{name}</div>;
}

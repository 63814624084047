const Accordion = require("./../Accordion.jsx");
const AccordionDisplay = require("./../AccordionDisplay.jsx");
const Anchor = require("./../Anchor.jsx");

const ExtensionMention = require("./ExtensionMention.jsx")


module.exports = ({
    extensions,
	show,
	toggleShow
}) => {
	module.exports.displayName = "MiniExtensionList";
    if (extensions.length === 1) {
        return <ExtensionMention extension={extensions[0]} link={true} />;
    }
    const {int_format, pluralize} = lib.numbers;

    return (
        <div>
            <div className="display-field.entry item">
                <Anchor className="item.link maxcols-padded-4"
                        action={toggleShow}>
                    Show {int_format(extensions.length)} <AccordionDisplay open={show} />
                </Anchor>
            </div>
            <Accordion open={show}>
                <ul className="list maxcols-4">
                    {
                        extensions.map((ext) => {
                            return (
                                <li key={`extension-${ext.id}`}
                                    className="display-field.entry item for-link">
                                    <ExtensionMention extension={ext} link={true}
                                                      className="to-right" />
                                </li>
                            );
                        })
                    }
                </ul>
            </Accordion>
        </div>
    );
}

const Label = (props) => {
  const { className, bold, text, showOnlyIf, htmlFor } = props;
  if (showOnlyIf !== undefined && !showOnlyIf) {
    return <span />;
  }
  const classes = lib.handle_classes(className, "field.label");

  return (
    <label htmlFor={props.for ?? htmlFor} className={classes}>
      {bold ? <b>{text}</b> : text}
    </label>
  );
};

Label.displayName = "Label";
module.exports = Label;

import * as api from "./api.js";
import { hash_to_json } from "./utilities.js";
import { spinner } from "./UI.js";

const browsing = (base_name, api_url = "", listing_store = "") => {
  const annotate_if_necessary = (data) => {
    Object.keys(data).forEach((key) => {
      const items = data[key];
      if (typeof items !== "object" || items.length === undefined) {
        return;
      }
      if (items.length > 1 && typeof items[0] === "number") {
        data[key] = items.map((n) => {
          return { id: n };
        });
      }
    });
  };

  return {
    _change_position: function () {
      const the_hash = hash_to_json();
      const position = parseInt(the_hash.position ?? "0", 10);
      this.dispatch(`${base_name}:change-position`, { position });
    },
    change_position: function () {
      const { actions } = this.flux;
      actions._change_position();
    },
    set_filters: function (filters) {
      this.dispatch(`${base_name}:set-filters`, { filters });
    },
    set_sorts: function (sorts) {
      this.dispatch(`${base_name}:set-sorts`, { sorts });
    },
    set_remainder: function (remainder) {
      this.dispatch(`${base_name}:set-remainder`, { remainder });
    },
    refresh_list: function () {
      const url = api.build_api_list_url(
        api_url,
        this.flux.stores[listing_store]
      );
      spinner.show();
      api.CRUD.read(url, (data) => {
        annotate_if_necessary(data);
        this.dispatch(`${base_name}:refresh-list`, data);
        this.flux.actions.change_position();
        spinner.hide();
      });
    },

    _refresh_list_with_glosses: function () {
      const url = api.build_api_list_url(
        api_url,
        this.flux.stores[listing_store]
      );
      spinner.show();
      api.CRUD.read(url, (data) => {
        annotate_if_necessary(data);
        this.dispatch(`${base_name}:refresh-list`, data);
        const { actions } = this.flux;
        actions._change_position();
        const { listing, position, page_size } = this.flux.stores[
          listing_store
        ].getState();
        if (listing.length > 0) {
          actions.get_page_glosses();
        } else {
          spinner.hide();
        }
      });
    },

    _change_position_with_glosses: function () {
      const { actions } = this.flux;
      actions._change_position();
      actions.get_page_glosses();
    },
    get_page_glosses: function () {
      const { listing, position, page_size } = this.flux.stores[
        listing_store
      ].getState();
      const use_position =
        position > 0 ? Math.max(0, position - page_size * 4) : position;
      const items = listing
        .slice(use_position, position + page_size * 5 + 1)
        .filter((item) => !item.is_gloss);
      if (items.length > 0) {
        const item_ids = items.map((item) => {
          const id = typeof item === "object" ? item.id : item;
          return id.toString();
        });
        const joined = item_ids.join(",");
        const url = `${api_url}?ids=${joined}&gloss=`;
        api.CRUD.read(url, (data) => {
          data.start = use_position;
          data.window = page_size * 9 + 1;
          this.dispatch(`${base_name}:update-list`, data);
          spinner.hide();
        });
      }
    },
  };
};

module.exports = {
  browsing,
};

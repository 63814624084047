const React = require("react");

const Accordion = require("./../Accordion.jsx");
const AccordionDisplay = require("./../AccordionDisplay.jsx");
const Anchor = require("./../Anchor.jsx");
const Dump = require("./../Dump.jsx");
const EditTrigger = require("./../EditTrigger.jsx");
const Entry = require("./../Entry.jsx");
const Field = require("./../Field.jsx");
const FieldGroup = require("./../FieldGroup.jsx");
const Label = require("./../Label.jsx");
const Select = require("./../Select.jsx");

const AddressDisplay = require("./AddressDisplay.jsx");
const AddressEditor = require("./AddressEditor.jsx");
const AddressHistoryDisplay = require("./AddressHistoryDisplay.jsx");

const Options = [
  { value: "revise", display: "Edit Current Address" },
  { value: "add", display: "Change Address and Archive Old" },
];
const null_option = { value: "none", display: "(select to modify)" };

module.exports = React.createClass({
  displayName: "AddressForm",
  changeOptions: Options,

  getInitialState: function () {
    return this.props;
  },
  componentWillReceiveProps: function (newProps) {
    this.setState(newProps);
  },

  toggleHistory: function () {
    this.setState({ show_history: !this.state.show_history });
  },

  handleEdit: function (e) {
    e.preventDefault();
    this.props.editAction();
  },
  handleChange: function (field, value) {
    const address = lib.obj.clone(this.props.address);
    address[field] = value;
    if (address.change === "none") {
      address.change = "revise";
    }
    this.props.updateAction(address);
  },

  renderEdit: function (labelClasses, entryClasses) {
    const address = this.state.address;
    const editorUpdater = (value) => {
      this.handleChange("current", value);
    };
    const changeUpdater = (e) => {
      this.handleChange("change", e.target.value);
    };
    return (
      <div>
        <AddressEditor
          address={address.current}
          updateAction={editorUpdater}
          showCompany={this.props.showCompany}
          companyPlaceholder={this.props.companyPlaceholder}
        />
        {this.props.addOnly ? (
          <span />
        ) : (
          <div>
            <br />
            <Select
              name="change"
              className="maxcols-padded-8"
              value={address.change}
              options={this.changeOptions}
              onChange={changeUpdater}
            />
            <br />
          </div>
        )}
      </div>
    );
  },
  renderDisplay: function (labelClasses, fieldClasses) {
    const { address } = this.state;
    const current = Object.assign(
      { company: "", state: "", zip: "", city: "", street: "" },
      address.current || {}
    );
    return (
      <EditTrigger onClick={this.handleEdit}>
        <AddressDisplay address={current} />
      </EditTrigger>
    );
  },
  renderAnyHistory: function () {
    const address = this.state.address;
    if ((address.history || []).length === 0) {
      return <span />;
    }
    const show_history = this.state.show_history;
    const classes = (this.props.historyClassName || "") + " flush-right";
    return (
      <div>
        <div className="item">
          <Anchor
            className="menu-item.link to-left"
            action={this.toggleHistory}
          >
            History <AccordionDisplay open={show_history} />
          </Anchor>
        </div>
        <Accordion open={show_history}>
          <AddressHistoryDisplay history={address.history} />
        </Accordion>
      </div>
    );
  },

  render: function () {
    if (this.state.address === undefined) {
      return <span />;
    }
    const fieldClasses = lib.handle_classes(
      lib.coalesce(this.props.fieldClassName, ""),
      "address-editor-field"
    );
    const labelClasses = lib.handle_classes(
      lib.coalesce(this.props.labelClassName, ""),
      "field.label"
    );
    const entryClasses = lib.handle_classes(
      lib.coalesce(this.props.entryClassName, ""),
      "field.multi"
    );

    return (
      <FieldGroup className={this.props.className}>
        <Field className={fieldClasses}>
          {this.props.hideLabel ? null : (
            <Label
              text={this.props.label || "Address:"}
              className={labelClasses}
            />
          )}
          <Entry className={entryClasses}>
            <div>
              {this.props.edit
                ? this.renderEdit(labelClasses, entryClasses)
                : this.renderDisplay(labelClasses, fieldClasses)}
              {this.renderAnyHistory()}
            </div>
          </Entry>
        </Field>
      </FieldGroup>
    );
  },
});

const Anchor = require("./../Anchor.jsx");

module.exports = ({
    lead,
    link,
    inline,
    className,
    remainder,
    tabIndex = undefined
}) => {
    module.exports.displayName = "LeadMention";

    const id = lead.id.toString().trim();
    if (link) {
        const classes = lib.handle_classes(className, "item.link");
        const linkURL = [
            standard.URL.leads.pages,
            "?item=",
            id,
            lib.coalesce(remainder, "").trim()
        ].join("");
        return (
            <Anchor className={classes} href={linkURL} tabIndex={tabIndex}>
                #{id}
            </Anchor>
        );
    }
    if (inline) {
        return <span className={className}>#{id}</span>;
    }
    return <div className={className}>#{id}</div>;
};

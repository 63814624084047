const React = require("react");

const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Entry = require("./../../Entry.jsx");
const TextInput = require("./../../TextInput.jsx");

const ProjectMention = require("./../ProjectMention.jsx");

module.exports = React.createClass({
  displayName: "PlacementEntry",
  render: function () {
    const { format } = lib.money;
    const { entry, updateAction } = this.props;
    const { project, costs, note } = entry;
    const { from_cents, to_cents } = lib.money;
    const updater = lib.updaters.wrapping({
      object: entry,
      action: updateAction,
      transform: (val) =>
        lib.money.to_obj(val.replace("$", "").replace(/[,]+/g, "")),
      object_transform: (new_entry) => {
        const result = lib.obj.clone(new_entry);
        const {
          ad_fee,
          placement_fee,
          cancellation_fee,
          other_fee,
        } = result.costs;
        result.costs.final_cost = from_cents(
          to_cents(ad_fee) +
            to_cents(placement_fee) +
            to_cents(cancellation_fee) +
            to_cents(other_fee)
        );
        result.changed = true;
        return result;
      },
    });
    const text_updater = lib.updaters.wrapping({
      object: entry,
      action: updateAction,
      object_transform: (new_entry) => {
        const result = lib.obj.clone(new_entry);
        result.changed = true;
        return result;
      },
    });

    const costs_budget = format(costs.budget);
    const costs_ad = format(costs.ad_fee);
    const costs_placement = format(costs.placement_fee);
    const costs_cancellation = format(costs.cancellation_fee);
    const costs_other = format(costs.other_fee);
    const costs_final = format(costs.final_cost);

    return (
      <FieldGroup className="maxcols-32">
        <Field className="maxcols-7">
          <ProjectMention
            project={project}
            abbr={false}
            className="field.entry maxcols-padded-7"
          />
        </Field>
        <Field className="maxcols-4">
          <TextInput
            className="maxcols-padded-3 numeric"
            mask={standard.masks.money}
            value={costs_budget}
            updateOn="blur"
            updateAction={updater(["costs", "budget"], costs_budget)}
          />
        </Field>
        <Field className="maxcols-3">
          <TextInput
            className="maxcols-padded-3 numeric"
            mask={standard.masks.money}
            value={costs_ad}
            updateOn="blur"
            updateAction={updater(["costs", "ad_fee"], costs_ad)}
          />
        </Field>
        <Field className="maxcols-3">
          <TextInput
            className="maxcols-padded-3 numeric"
            mask={standard.masks.money}
            value={costs_placement}
            updateOn="blur"
            updateAction={updater(["costs", "placement_fee"], costs_placement)}
          />
        </Field>
        <Field className="maxcols-3">
          <TextInput
            className="maxcols-padded-3 numeric"
            mask={standard.masks.money}
            value={costs_cancellation}
            updateOn="blur"
            updateAction={updater(
              ["costs", "cancellation_fee"],
              costs_cancellation
            )}
          />
        </Field>
        <Field className="maxcols-3 numeric">
          <TextInput
            className="maxcols-padded-3 numeric"
            mask={standard.masks.money}
            value={costs_other}
            updateOn="blur"
            updateAction={updater(["costs", "other_fee"], costs_other)}
          />
        </Field>
        <Field className="maxcols-3">
          <Entry className="maxcols-padded-3 numeric">
            {format(costs.final_cost)}
          </Entry>
        </Field>
        <Field className="maxcols-6">
          <TextInput
            className="maxcols-padded-6"
            value={note}
            updateAction={text_updater("note")}
          />
        </Field>
      </FieldGroup>
    );
  },
});

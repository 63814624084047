import {time} from "./../../libraries";

const Time = require("./../Time.jsx");

module.exports = ({entry}) => {
    module.exports.displayName = "ContactEntryDisplay";
    const {home_phone, work_phone, mobile_phone, fax, other, email} = entry;
    const {blank} = lib.str;
    const all_blank =
        [home_phone, work_phone, mobile_phone, fax, email].every(f =>
            blank(f)
        ) && blank(other.number);
    if (all_blank) {
        return (
            <div className="text">
                <Time timestamp={entry.recorded} className="header-time" />
                <br />
                <i>(blank)</i>
            </div>
        );
    }
    const show = (label, field) => {
        return blank(field) ? (
            <span />
        ) : (
            <div>
                {label}: {field}
            </div>
        );
    };
    return (
        <div className="maxcols-12">
            <div className="text">
                <Time timestamp={entry.recorded} className="header-time" />
            </div>
            <div className="text maxcols-padded-12">
                {show("Home", home_phone)}
                {show("Work", work_phone)}
                {show("Mobile", mobile_phone)}
                {show("Fax", fax)}
                {show(other.name, other.number)}
                {show("Email", email)}
            </div>
        </div>
    );
};

module.exports = (props) => {
	module.exports.displayName = "TerritoryOutlineProject";
	
	return (
		<div>
			<span className="item.stub">
				{(props.name || (<i>no projects set</i>))}
			</span>
		</div>
	);
}

const Accordion = require("./../Accordion.jsx");
const AccordionDisplay = require("./../AccordionDisplay.jsx");
const Entry = require("./../Entry.jsx");
const Field = require("./../Field.jsx");
const FieldGroup = require("./../FieldGroup.jsx");
const Label = require("./../Label.jsx");
const NotesList = require("./NotesList.jsx");
const NotesDisplay = require("./NotesDisplay.jsx");
const TextInput = require("./../TextInput.jsx");


module.exports = ({
    notes=[],
    show,
    toggleShow,
	className,
    blankText=""
}) => {
    module.exports.displayName = "NotesForm";
    const classes = lib.handle_classes(
        className, "notes-form"
    );
    const col_classes = classes.split(" ").filter(
        c => c.indexOf("maxcol") > -1
    );
    const accordion_class = (
        col_classes.length > 0
        ?
        col_classes[0] + " middle"
        :
        "middle"
    );
    const handleToggle = (e) => {
        e.preventDefault();
        toggleShow();
    };
    const {pluralize, int_format} = lib.numbers;

    if (notes.length === 0) {
		if (blankText === "") {
			return <span />;
		}
		return <legend>{blankText}</legend>;
    }

    return (
        <div style={{width:"100%"}}>
            <legend className="item">
                <a className="menu-item.link to-left" tabIndex="0" href="#"
                   onClick={handleToggle}>
                    {
                        int_format(notes.length)
                    } {
                        pluralize(notes.length, "Existing Note", "Existing Notes")
                    } <AccordionDisplay open={show} />
                </a>
            </legend>
            <Accordion open={show} large={true} className={accordion_class}>
                <br />
                <NotesList notes={notes} />
                <br />
            </Accordion>
        </div>
    );
}

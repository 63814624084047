import {
  cmp,
  coalesce,
  iter,
  obj,
  sorters,
  str,
  text,
  yes_or_no,
} from "./basic.js";

import {
  integer_formatter,
  money,
  numbers,
  us_money_formatter,
} from "./numbers.js";

import { date, time } from "./date_and_time.js";

import {
  empty_hash,
  empty_query,
  handle_classes,
  hash_to_json,
  its_me,
  query_params,
  query_string_to_json,
  ready,
} from "./utilities.js";

import { options, updaters } from "./fluxing.js";

import * as actions from "./actions.js";

import * as api from "./api.js";

import * as comp from "./components.jsx";

import * as domain from "./domain.js";

import * as models from "./models";

import * as standard from "./standard.js";

import * as stores from "./stores.js";

import * as ui from "./UI.js";

import * as validation from "./validation.js";

const moment = require("moment");
const mathjs = require("mathjs");
mathjs.config({ number: "BigNumber" });
mathjs.typed.conversions.unshift({
  from: "Fraction",
  to: "BigNumber",
  convert: (fraction) => {
    return new mathjs.type.BigNumber(fraction.n).div(fraction.d);
  },
});

module.exports = {
  cmp,
  coalesce,
  iter,
  obj,
  sorters,
  str,
  text,
  yes_or_no,

  money,
  numbers,

  date,
  time,

  empty_hash,
  empty_query,
  handle_classes,
  hash_to_json,
  its_me,
  query_params,
  query_string_to_json,
  ready,

  options,
  updaters,

  actions,

  api,

  comp,

  domain,

  models,

  standard,

  stores,

  ui,

  validation,

  third_party: {
    mathjs,
    moment,
  },
};

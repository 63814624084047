const bad_time = t => {
    return t === undefined || t.trim;
};

module.exports = ({
    timestamp,
    className,
    showOnBlank,
    formatter = lib.time.format.plain.date
}) => {
    module.exports.displayName = "TimeStamp";
    if (bad_time(timestamp)) {
        return showOnBlank !== undefined ? (
            <span>{showOnBlank}</span>
        ) : (
            <span>&mdash;</span>
        );
    }
    const classes = lib.handle_classes(className, "time");
    const this_time = lib.time.from_stamp(timestamp);

    return (
        <time dateTime={this_time.toISOString()} className={classes}>
            {formatter(this_time)}
        </time>
    );
};

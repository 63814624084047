const Anchor = ({
  action,
  actionValue,
  onClick,
  children,
  className,
  target,
  rel,
  title,
  style,
  href = "#",
  tabIndex = 0,
  showOnlyIf = true,
}) => {
  if (!showOnlyIf) {
    return <span />;
  }
  const handleClick = (e) => {
    if (onClick !== undefined) {
      onClick(e);
      return;
    }
    if (action !== undefined) {
      e.preventDefault();
      if (actionValue !== undefined) {
        action(actionValue);
      } else {
        action();
      }
      return;
    }
  };

  return (
    <a
      className={className}
      style={style}
      onClick={handleClick}
      href={href}
      rel={rel}
      target={target}
      tabIndex={tabIndex}
      title={title}
    >
      {children}
    </a>
  );
};

Anchor.displayName = "Anchor";
module.exports = Anchor;

module.exports = ({ lat, lon }) => {
  module.exports.displayName = "GoogleMapsLink";

  if (lat === undefined || lon === undefined) {
    return <br />;
  }
  const {coord_num} = lib.domain;
  const maps_link = `http://www.google.com/maps/place/${coord_num(
    lat
  )},${coord_num(lon)}`;

  return (
    <a className="item.link" href={maps_link}>
      Google Maps Link
    </a>
  );
};

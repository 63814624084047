const AttachmentLink = require("./AttachmentLink.jsx");

module.exports = ({
  attachments,
  updateAction,
  baseURL,
  className,
  id,
  maxLength,
  text,
}) => {
  module.exports.displayName = "AttachmentLinkList";

  if (lib.coalesce(attachments, []).length === 0) {
    return <span />;
  }
  const classes = lib.handle_classes(className, "list");
  const length =
    maxLength === undefined
      ? attachments.length
      : Math.min(maxLength, attachments.length);

  return (
    <ul className={classes}>
      {attachments.slice(0, length).map((attachment, i) => {
        const ident = lib.coalesce(attachment.id, i);
        const key = `${id}-${ident}`;
        const this_action = updateAction.nest(i);
        return (
          <li className="item" key={key}>
            <AttachmentLink
              baseURL={baseURL}
              attachment={attachment}
              updateAction={this_action}
              text={text}
            />
          </li>
        );
      })}
    </ul>
  );
};

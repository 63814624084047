const Anchor = require("./../Anchor.jsx");

module.exports = ({project, link, inline, abbr = false, className}) => {
    module.exports.displayName = "ProjectMention";

    if (project === undefined) {
        return <span />;
    }
    const abbreviation = lib.coalesce(
        project.abbr,
        project.name
            .replace("The ", "")
            .replace(/ /g, "")
            .split("")
            .filter(c => c === c.toUpperCase())
            .join(".")
    );
    const inner = abbr ? (
        <abbr title={project.name}>{abbreviation}</abbr>
    ) : (
        project.name
    );
    if (link) {
        const classes = lib.handle_classes(className, "item.link");
        return (
            <Anchor
                className={classes}
                href={`${standard.URL.projects.pages}?item=${project.id}`}>
                {inner}
            </Anchor>
        );
    }
    if (inline) {
        return <span className={className}>{inner}</span>;
    }
    return <div className={className}>{inner}</div>;
};

// import $ from "./third-party/jquery-1.12.3.min.js";
// import Modernizer from "./modernizr.js";

//import "nodep-date-input-polyfill";

export var setup_form_helpers = () => {
  (() => {
    $(".FileSelect").change((e) => {
      var here = $(e.currentTarget);
      var field = here.closest(".field");
      if (here.val() === "") {
        field.find(".VisibleFileSelect").show();
        field.find(".FileUpload").hide();
      } else {
        field.find(".FileUpload").show();
        field.find(".VisibleFileSelect").hide();
      }
    });
    $(".FileUpload")
      .hide()
      .click((e) => {
        e.preventDefault();
        var here = $(e.currentTarget);
        var form = here.closest("form");
        var checkID = here.data("check");
        if (checkID !== undefined) {
          var check = $("#" + checkID);
          if (check.val() === "") {
            alert("Pick a file.");
            return;
          }
        }
        form.submit();
      });
  })();

  (() => {
    $(".MultiSubmit").click((e) => {
      e.preventDefault();
      var here = $(e.currentTarget);
      var form = here.closest("form");
      form.submit();
    });
  })();

  (() => {
    $(".ClearField").click((e) => {
      e.preventDefault();
      var here = $(e.currentTarget);
      var target_id = here.data("field-id");
      if (target_id === undefined) return;
      var target = $("#" + target_id);
      target.val("");
    });
  })();

  // Report Forms

  (() => {
    $(".OptionChanger").change((e) => {
      const here = $(e.currentTarget);
      const form = here.closest("form");
      form.submit();
    });
  })();
};

const React = require("react");

const Option = ({ id, opt }) => {
  if (opt === undefined) {
    return <span />;
  }
  const this_value = opt.value === undefined ? "" : opt.value.toString();
  const to_display = [opt.display, opt.value, ""].find(
    (x) => typeof x === "string"
  );
  return <option value={opt.value}>{to_display.trim()}</option>;
};

module.exports = React.createClass({
  displayName: "Select",

  handleChange: function (e) {
    const props = this.props;
    const multiple = props.multiple === true;
    if (props.onChange !== undefined) {
      props.onChange(e);
      return;
    }

    if (props.updateAction !== undefined) {
      const selected = {};
      [...e.target.options]
        .filter((opt) => {
          return opt.selected;
        })
        .forEach((opt) => {
          selected[opt.value] = "";
        });

      const value = props.options
        .filter((opt) => {
          return selected.hasOwnProperty(opt.value);
        })
        .map((opt) => {
          return opt.actual || opt.value;
        });
      props.updateAction(multiple ? value : value[0]);
      return;
    }
  },
  getSelected: function () {
    const { props } = this;
    if (props.multiple) {
      if (lib.iter.is_empty_array(props.value) || props.value === undefined) {
        return [];
      }
    }
    if (props.value === undefined) {
      return undefined;
    }

    const express_as_option = (raw) => {
      const value = lib.coalesce(
        raw[lib.coalesce(props.valueField, "value")],
        raw.id,
        raw
      );
      const display = lib.coalesce(
        raw[lib.coalesce(props.displayField, "display")],
        raw.name,
        raw
      );
      return { value, display, raw };
    };

    if (lib.coalesce(props.multiple, false)) {
      if (props.value.length > 0) {
        return props.value.map(express_as_option);
      }
    } else {
      return express_as_option(props.value);
    }
  },
  render: function () {
    const {
      id,
      name,
      options,
      error,
      warning,
      className,
      multiple,
      size,
      disabled,
      showOnlyIf,
      tabIndex,
      title,
      autoFocus = false,
    } = this.props;

    if (!lib.coalesce(showOnlyIf, true)) {
      return <span />;
    }

    const any_error = error !== undefined;
    const any_warning = warning !== undefined;
    const classes =
      lib.handle_classes(className, "field.entry") +
      (any_warning ? " warning-notice" : "");
    const selected = lib.coalesce(
      this.getSelected(),
      (options ?? []).length > 0 ? options[0] : {}
    );

    const get_value = (selected, multiple) => {
      const value = lib.iter.is_array(selected)
        ? selected.map((x) => x.value)
        : selected.value;
      if (!lib.iter.is_array(value) && lib.coalesce(multiple, false)) {
        return [value];
      }
      return value;
    };
    const value = get_value(selected, multiple);

    return (
      <select
        id={id ?? name}
        name={name ?? id}
        className={classes}
        multiple={multiple}
        size={size}
        aria-invalid={any_error}
        disabled={disabled}
        value={value}
        onChange={this.handleChange}
        tabIndex={tabIndex}
        title={title}
        autoFocus={autoFocus}
      >
        {lib.coalesce(options, []).length > 0 ? (
          options.map((opt, i) => {
            return <Option id={id} opt={opt} key={`option-${id}-${i}`} />;
          })
        ) : (
          <Option id={id} opt={selected} />
        )}
      </select>
    );
  },
});

module.exports = (props) => {
  module.exports.displayName = "CheckBox";
  var classes = lib.handle_classes(props.className, "checked-field.entry");
  var default_true = (source) => {
    return source === undefined ? true : source;
  };
  var true_value = default_true(props.trueValue);
  var check_on = default_true(props.checkOn);
  var checked = props.value === check_on;
  var any_error = props.error !== undefined;
  var toggle_check = (val) => {
    return !checked;
  };
  return (
    <label className={classes} data-checked={checked}>
      <input
        id={props.id || props.name}
        name={props.name}
        type="checkbox"
        value={true_value}
        checked={checked}
        disabled={props.disabled || false}
        onChange={standard.handlers.basic_change(props, toggle_check)}
      />
      <span className="slider round"></span>
    </label>
  );
};

/*
   StillForm.jsx
   ----------------------------------------------------------------------------
   This component exists to wrap around other components or HTML form elements
   and quash submit events.  You can then wire in external submit events.

   Attributes:

   onSubmit - optional function of signature (e)
   
   You can provide an external event handler here to do things with the
   form's submit event.  Default action is already prevented.
   
   hiddenSubmit - boolean, default false
   
   Set true to create a hidden submit field.  This makes the form fire
   a submit event if the user hits Enter/Return within a contained input
   field.
 */

var React = require("react");

module.exports = React.createClass({
  displayName: "StillForm",
  handleSubmit: function (e) {
    e.preventDefault();
    if (this.props.onSubmit !== undefined) {
      this.props.onSubmit(e);
    }
  },

  render: function () {
    var children = this.props.children;
    const classes = lib.handle_classes(this.props.className, "");

    return (
      <form id={this.props.id} className={classes} onSubmit={this.handleSubmit}>
        {React.Children.map(children, (child) => {
          return child;
        })}
        {this.props.hiddenSubmit || false ? (
          <input type="submit" style={{ display: "none" }} />
        ) : null}
      </form>
    );
  },
});

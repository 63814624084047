const TimeStamp = require("./TimeStamp.jsx");

module.exports = ({
    time,
    className,
    formatter = lib.time.format.normal,
    showZone = true
}) => {
    module.exports.displayName = "TimeWithZone";
    const {timestamp, time_zone} = time;
    const classes = lib.handle_classes(className, "wrapped-time");

    return (
        <span className={classes}>
            <TimeStamp timestamp={timestamp} formatter={formatter} />
            {time_zone === undefined || !showZone ? null : (
                <span className="wrapped-time.zone"> {time_zone.name}</span>
            )}
        </span>
    );
};

const column_width = 32;

const ajax_setup = {
  cache: false,
  timeout: 120 * 1000,
};

// Input masks for TextInput and other components
// Note that a mask regex has to work for incompletely entered values too,
// like partially entered ZIP codes.
const masks = {
  date: (val) => {
    if (val === "") {
      return true;
    }
    const filtered = val.replace(/[^0-9\/]*/g, "");
    if (filtered !== val) {
      return false;
    }
    if (val.replace("//", "") !== val) {
      return false;
    }

    const num_in_range = (num, min, max) => {
      if (num === "") {
        return true;
      }
      if (isNaN(num)) {
        return false;
      }
      const number = parseInt(num, 10);
      return min <= number && num <= max;
    };

    const parts = val.split("/");
    if (parts.length > 0) {
      const month = parts[0];
      if (!num_in_range(month, 1, 12)) {
        return false;
      }
    }
    if (parts.length > 1) {
      const day = parts[1];
      if (!num_in_range(day, 1, 31)) {
        return false;
      }
    }
    if (parts.length > 2) {
      const year = parts[2];
      if (isNaN(year)) {
        return false;
      }
      if (year.length === 3) {
        if (!num_in_range(year, 190, 209)) {
          return false;
        }
      }
      if (year.length > 3) {
        if (!num_in_range(year, 1900, 2099)) {
          return false;
        }
      }
    }
    if (parts.length > 3) {
      return false;
    }

    return true;
  },

  date_month: /^[01]{0,1}\d{0,1}$/,
  date_year: /^(20){0,1}\d{0,2}$/,

  digits: /^\d*$/,
  decimal: /^\d*[.]{0,1}\d*$/,
  percent_under_100: /^\d{0,2}([.]{1}\d{0,4})?$/,
  ZIP: /(^\d{0,5}$)|(^\d{5}-\d{0,4}$)/,
  money: /^\d*([.]{1}\d{0,2})?$/,

  latitude: /^-{0,1}\d{0,2}([.]\d{0,8}){0,1}$/,
  longitude: /^-{0,1}[1]{0,1}\d{0,2}([.]\d{0,8}){0,1}$/,

  no_whitespace: /^\S*$/,
};

const URL = {
  ads: { pages: "/Ads.cfm", api: "/api/Marketing/AdInfo/" },
  announcements: {
    api: "/api/Announcements/",
    pages: "/Announcements.cfm",
  },
  appointments: {
    pages: "/Appointments.cfm",
    api: "/api/Process/Appointments/",
    bulk_api: "/api/Process/Appointments/bulk/",
  },
  appointment_settings: { api: "/api/Process/AppointmentSettings/" },
  companies: { pages: "/Companies.cfm", api: "/api/Organization/Companies/" },
  contracts: { pages: "/Contracts.cfm", api: "/api/Process/Contracts/" },
  disclaimers: {
    pages: "/Options/Disclaimers.cfm",
    api: "/api/Organization/Options/Disclaimers/",
  },
  employees: { pages: "/Employees.cfm", api: "/api/Employees/" },
  extensions: { pages: "/Extensions.cfm", api: "/api/Marketing/Extensions/" },
  external_imports: {
    pages: "ExternalImports.cfm",
    api: "/api/Process/ExternalImports/",
  },
  financing: {
    api: "/api/Marketing/Financing/",
  },
  home_models: { pages: "/HomeModels.cfm", api: "/api/HomeModels/" },
  leads: { pages: "/Leads.cfm", api: "/api/Process/Leads/" },
  lead_settings: { api: "/api/Process/LeadSettings/" },
  lots: { pages: "/Lots.cfm", api: "/api/Lots/" },
  price_list: { pages: "/PriceList.cfm", api: "/api/Lots/PriceList/" },
  options: { pages: "/Options.cfm", api: "/api/Organization/Options/" },
  projects: { pages: "/Projects.cfm", api: "/api/Organization/Projects/" },
  sources: { pages: "/Sources.cfm", api: "/api/Marketing/Sources/" },
  source_origins: {
    pages: "/SourceOrigins.cfm",
    api: "/api/Marketing/SourceOrigins/",
  },
  source_types: {
    pages: "/SourceTypes.cfm",
    api: "/api/Marketing/SourceTypes/",
  },

  touchpoints: { pages: "/Touchpoints.cfm", api: "/api/Touchpoints/" },

  active_extensions_by_project: {
    api: "/api/Marketing/Extensions/ActiveByProject/",
  },
  demographics: { api: "/api/Process/Demographics" },
  active_salesreps_by_project: {
    api: "/api/Process/ProjectsActiveSalesreps/",
  },
  all_salesreps_by_project: { api: "/api/Process/ProjectsSalesreps/" },
  closings_calendar: { api: "/api/Process/ClosingCalendar/" },
  organization: { api: "/api/Organization/" },
  states: { api: "/api/Organization/States" },
  structure: { api: "/api/Organization/Structure" },
  kit_bulk_entry: { api: "/api/Process/Leads/Bulk/" },
  title_companies: {
    pages: "/TitleCompanies.cfm",
    api: "/api/Organization/TitleCompanies/",
  },
  mortgage_companies: {
    pages: "/MortgageCompanies.cfm",
    api: "/api/Organization/MortgageCompanies/",
  },

  dashboard: {
    api: "/api/Process/Dashboard/",
  },
};

const handlers = {
  basic_change: (
    props,
    func = (v) => {
      return v;
    }
  ) => {
    // Not viable for multi-selects
    return (e) => {
      if (props.onChange !== undefined) {
        props.onChange(e);
        return;
      }
      var updater = props.updater ?? props.updateAction;
      if (updater !== undefined) {
        var value = func(e.target.value.toString());
        updater(value);
        return;
      }
    };
  },
};

module.exports = {
  ajax_setup,
  column_width,
  handlers,
  masks,
  URL,
};

const React = require("react");

const Accordion = require("./../Accordion.jsx");
const AccordionControl = require("./../AccordionControl.jsx");
const AccordionDisplay = require("./../AccordionDisplay.jsx");
const Anchor = require("./../Anchor.jsx");
const DateEntry = require("./../DateEntry.jsx");
const Dump = require("./../Dump.jsx");
const FieldGroup = require("./../FieldGroup.jsx");
const Field = require("./../Field.jsx");
const Label = require("./../Label.jsx");
const Entry = require("./../Entry.jsx");
const CheckBox = require("./../CheckBox.jsx");
const TextInput = require("./../TextInput.jsx");
const Select = require("./../Select.jsx");

const TerritoryHistory = require("./TerritoryHistory.jsx");

const Process = {
  project_toggler: (project, territory, structure, path, updater) => {
    return (value) => {
      const updated = lib.obj.clone(territory);
      if (value) {
        Process.add_up_to(updated, path);
      } else {
        Process.cut_back(updated, path);
      }
      updater([])(updated);
    };
  },
  date_changer: (path, updater) => {
    return (field) => {
      return (value) => {
        updater([...path, field])(value);
      };
    };
  },
  level_selective_toggler: (structure, territory, path, updater) => {
    const all_path = Process.level_selective_path(path);
    return (value) => {
      if (value) {
        Process.add_up_to(territory, path);
        updater(all_path)(value);
        Process.cascade_down_to_projects(structure, territory);
      } else {
        updater(all_path)(value);
      }
    };
  },
  level_clear: (structure, territory, path, updater) => {
    return () => {
      Process.level_selective_toggler(structure, territory, path, updater)();
      Process.cut_back(territory, path.slice(0, -1));
    };
  },

  level_selective_value: (territory, path) => {
    return lib.obj.path_lookup_direct(
      territory,
      Process.level_selective_path(path)
    );
  },
  level_selective_path: (path) => {
    const level = path[path.length - 1];
    return [...path.slice(0, -1), "all_" + level];
  },

  add_up_to: (territory, path) => {
    const { path_exists, path_lookup, path_lookup_direct } = lib.obj;
    for (let i = 1; i < path.length; i += 2) {
      const sub_path = path.slice(0, i + 1);
      if (!path_exists(territory, sub_path)) {
        Process.add_new_entity(territory, sub_path);
      }
    }
  },
  add_new_entity: (territory, path) => {
    const make = (level, id) => {
      const Lookup = {
        companies: { id: id, states: {}, all_states: false },
        states: { abbr: id, markets: {}, all_markets: false },
        markets: { id: id, projects: {}, all_projects: false },
        projects: {
          id: id,
          current: true,
          start_date: lib.date.to_iso(lib.time.today()),
          stop_date: "",
        },
      };
      return Lookup[level];
    };
    const level = path[path.length - 2];
    const id = path[path.length - 1];
    const new_entry = make(level, id);
    if (territory.companies === undefined) {
      territory.companies = {};
    }

    const position = lib.obj.path_lookup_direct(territory, path.slice(0, -1));
    position[id] = new_entry;
  },
  cut_back: (territory, path) => {
    if (path.length === 0) {
      territory.companies = {};
      return;
    }
    const pos = lib.obj.path_lookup_direct(territory, path.slice(0, -1));
    const key = path[path.length - 1];
    delete pos[key];
    Process.winnow_empty(territory, path);
  },
  winnow_empty: (territory, path) => {
    const { is_empty, path_lookup_direct } = lib.obj;
    const level = (x) => path_lookup_direct(territory, path.slice(0, x));

    const projects = level(7);
    const market = level(6);
    const markets = level(5);
    const state = level(4);
    const states = level(3);
    const company = level(2);
    const companies = level(1);

    if (is_empty(projects) && market !== undefined) {
      delete markets[market.id];
    }
    if (is_empty(markets) && state !== undefined) {
      delete states[state.abbr];
    }
    if (is_empty(states) && company !== undefined) {
      delete companies[company.id];
    }
  },

  cascade_down_to_projects: (structure, territory) => {
    const lookup = lib.obj.path_lookup_direct;
    const coalesce = lib.iter.coalesce;
    const paths = { company: [], state: [], market: [], project: [] };
    const t = {
      all_companies: territory.all_companies,
      company: {},
      state: {},
      market: {},
      project: {},
    };
    const add_on_flag = (flag, path_of) => {
      if (flag) {
        Process.add_up_to(territory, path_of);
      }
    };
    const find_item = (path_of, all_key) => {
      const dummy = {};
      dummy[all_key] = false;
      return coalesce(lookup(territory, path_of), dummy);
    };
    const fetch = (flag, path_of, all_key) => {
      add_on_flag(flag, path_of);
      return find_item(path_of, all_key);
    };
    for (let company of structure) {
      paths.company = ["companies", company.id];
      t.company = fetch(t.all_companies, paths.company, "all_states");
      t.company.all_states = t.all_companies || t.company.all_states;
      for (let state of company.states) {
        paths.state = [...paths.company, "states", state.abbr];
        t.state = fetch(t.company.all_states, paths.state, "all_markets");
        t.state.all_markets = t.company.all_states || t.state.all_markets;
        for (let market of state.markets) {
          paths.market = [...paths.state, "markets", market.id];
          t.market = fetch(t.state.all_markets, paths.market, "all_projects");
          t.market.all_projects = t.state.all_markets || t.market.all_projects;
          if (t.market.all_projects) {
            for (let project of market.projects) {
              paths.project = [...paths.market, "projects", project.id];
              Process.add_up_to(territory, paths.project);
            }
          }
        }
      }
    }
  },
};

const LevelName = ({ name, selected, active }) => {
  let name_display = name.toString();
  name_display = active ? name_display : <i>{name_display}</i>;
  name_display = selected ? <b>{name_display}</b> : name_display;
  return <span>{name_display}</span>;
};

const ProjectLevel = ({
  project,
  structure,
  territory,
  path,
  updater,
  errors,
}) => {
  ProjectLevel.displayName = "ProjectLevel";

  const { path_exists, path_lookup_direct } = lib.obj;
  const { name, active } = project;
  const id = project.id.toString();

  const selected = path_exists(territory, path);
  const all_projects = Process.level_selective_value(
    territory,
    path.slice(0, -1)
  );
  const specs = lib.iter.coalesce(path_lookup_direct(territory, path), {});
  const { start_date, stop_date } = specs;
  const date_changer = Process.date_changer(path, updater);

  return (
    <Field className="maxcols-16">
      <CheckBox
        className="maxcols-padded-2"
        value={selected}
        disabled={all_projects}
        updateAction={Process.project_toggler(
          project,
          territory,
          structure,
          path,
          updater
        )}
      />
      <div className="item.stub maxcols-padded-8">
        <LevelName name={name} active={active} selected={selected} />
      </div>
      {selected ? (
        <div className="field.multi maxcols-6">
          <DateEntry
            date={start_date}
            updateAction={date_changer("start_date")}
            error={errors[id + "-start_date"]}
            placeholder="Starting"
          />
          <DateEntry
            date={stop_date}
            updateAction={date_changer("stop_date")}
            error={errors[id + "-stop_date"]}
            placeholder="Stopping"
          />
        </div>
      ) : null}
    </Field>
  );
};

const LevelControl = ({ name, active, selected, toggle, toggleAction }) => {
  LevelControl.displayName = "LevelControl";
  const { coalesce } = lib.iter;
  const handleToggle = (e) => {
    e.preventDefault();
    toggleAction();
  };

  return (
    <Field className="maxcols-12">
      <label className="field.label maxcols-padded-10" onClick={handleToggle}>
        <LevelName name={name} active={active} selected={selected} />
        &nbsp;
        <AccordionDisplay open={toggle} />
      </label>
    </Field>
  );
};

const TerritoryDetail = ({
  id, // Top-level id for base list
  keyUsed,
  structure,
  fullStructure,
  path,
  subFields,
  item,
  errors,
  territory,
  updater,
  toggles,
  toggleAction,
  showInactive,
  showUnselected,
}) => {
  TerritoryDetail.displayName = "TerritoryDetail";

  const { name, active } = item;
  const selected = lib.obj.path_exists(territory, path);
  const info = lib.iter.coalesce(
    lib.obj.path_lookup_direct(territory, path),
    {}
  );
  if ((!showInactive && !active) || (!showUnselected && !selected)) {
    return <noscript />;
  }

  const sub_key = subFields[0];
  const sub_items = lib.iter.coalesce(item[sub_key], []);

  if (subFields.length === 0) {
    return (
      <ProjectLevel
        project={item}
        structure={structure}
        territory={territory}
        path={path}
        updater={updater}
        errors={errors}
        selected={selected}
      />
    );
  }
  if (sub_items.length === 0) {
    return (
      <Field className="maxcols-12">
        <label className="field.label maxcols-padded-10">{name}</label>
      </Field>
    );
  }

  const toggler = () => {
    toggleAction(keyUsed);
  };

  return (
    <li className="item">
      <LevelControl
        name={name}
        active={active}
        selected={selected}
        toggle={toggles[keyUsed]}
        toggleAction={toggler}
      />
      <Accordion open={toggles[keyUsed]}>
        <AllvsSelectedToggle
          id={id}
          structure={fullStructure}
          territory={territory}
          path={[...path, sub_key]}
          updater={updater}
        />
        <TerritoryDetailList
          id={id}
          structure={sub_items}
          fullStructure={fullStructure}
          subFields={subFields}
          path={[...path, sub_key]}
          territory={territory}
          updater={updater}
          errors={errors}
          toggles={toggles}
          toggleAction={toggleAction}
          showInactive={showInactive}
          showUnselected={true}
        />
      </Accordion>
    </li>
  );
};

const TerritoryDetailList = ({
  id,
  structure,
  fullStructure,
  subFields,
  path,
  territory,
  updater,
  errors,
  toggles,
  toggleAction,
  showInactive,
  showUnselected,
}) => {
  TerritoryDetailList.displayName = "TerritoryDetailList";

  return (
    <ul className="list">
      {structure.map((item, i) => {
        const item_id = lib.iter.coalesce(item.id, item.abbr);
        const key = [id, ...path, item_id].join("-");
        const name = { item };
        return (
          <TerritoryDetail
            id={id}
            key={key}
            keyUsed={key}
            structure={structure}
            fullStructure={lib.iter.coalesce(fullStructure, structure)}
            subFields={subFields.slice(1)}
            path={[...path, item_id]}
            item={item}
            errors={errors}
            territory={territory}
            updater={updater}
            toggles={toggles}
            toggleAction={toggleAction}
            showInactive={showInactive}
            showUnselected={showUnselected}
          />
        );
      })}
    </ul>
  );
};

const AllvsSelectedToggle = ({ id, structure, territory, path, updater }) => {
  AllvsSelectedToggle.displayName = "AllvsSelectedToggle";
  const this_id = [id, ...path, "show-unselected"].join("-");
  const sub_item_term = path[path.length - 1];
  const label = lib.str.title_case(sub_item_term);
  const options = lib.options.as_boolean("Selected " + label, "All " + label);
  const value = Process.level_selective_value(territory, path);
  const parent_is_all_select = Process.level_selective_value(
    territory,
    path.slice(0, -2)
  );
  const updateAction = Process.level_selective_toggler(
    structure,
    territory,
    path,
    updater
  );
  const handleClear = (e) => {
    e.preventDefault();
    const clear_this = Process.level_clear(structure, territory, path, updater);
    const warning_text =
      sub_item_term === "companies"
        ? `Are you sure you want to clear ALL ${sub_item_term}?`
        : `Are you sure you want to clear ALL of these ${sub_item_term}?`;
    if (confirm(warning_text)) {
      clear_this();
    }
  };

  return (
    <Field className="maxcols-12">
      <Select
        id={this_id}
        className="maxcols-padded-6"
        options={options}
        value={value}
        disabled={parent_is_all_select}
        updateAction={updateAction}
      />
      {value && !parent_is_all_select ? (
        <div className="flex-this maxcols-5">
          <button
            className="warning-button maxcols-padded-4 flush-right"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      ) : null}
    </Field>
  );
};

module.exports = React.createClass({
  displayName: "TerritoryForm",

  debug: false,

  /*
    shouldComponentUpdate(nextProps, nextState) {
        const present = {
            toggles: this.getRelevantToggles(this.props.toggles),
            value: this.props.value
        };
        const future = {
            toggles: this.getRelevantToggles(nextProps.toggles),
            value: nextProps.value
        };
        const whether = JSON.stringify(present) !== JSON.stringify(future);
        if (this.debug && whether) {
            console.log("Updating TerritoryForm");
        }
        return whether;
    },
    */

  getRelevantToggles: function (toggles) {
    const relevant_keys = Object.keys(toggles).filter((t) =>
      t.includes("territory")
    );
    const result = {};
    relevant_keys.forEach((key) => {
      result[key] = toggles[key];
    });
    return result;
  },

  render: function () {
    const {
      id, // Unique base identifier
      structure, // The full structure of all posssible territory
      value, // The actual, edited territory
      updater, // Send the modified territory value to this
      toggles, // The value of the toggle-store
      toggleAction, // Updates the toggle-store,
      errors, // Error structure
    } = this.props;

    if (structure === undefined) {
      return <div>Loading territory&hellip;</div>;
    }

    const subFields = ["base", "states", "markets", "projects"];

    const unselected_key = id + "-show-unselected-details";
    const inactive_key = id + "-show-inactive-details";
    const showInactive = toggles[inactive_key];
    const showUnselected =
      toggles[unselected_key] || lib.obj.is_empty(value.companies);
    const toggler = (name) => {
      return () => {
        toggleAction(name);
      };
    };
    const show_history = toggles["show territory history"];

    const relevant_toggles = this.getRelevantToggles(toggles);

    return (
      <div className="maxcols-24 middle">
        <AccordionControl
          className="to-left maxcols-24"
          show={toggles["territory"]}
          showAction={toggler("territory")}
          legend={true}
        >
          Territory
        </AccordionControl>

        <Accordion
          open={toggles["territory"]}
          large={true}
          className="maxcols-24"
        >
          <FieldGroup className="maxcols-24">
            <AllvsSelectedToggle
              id={id}
              structure={structure}
              territory={value}
              path={["companies"]}
              updater={updater}
            />
            <Field className="maxcols-12">
              <Field className="maxcols-8">
                <Select
                  name="show-unselected"
                  className="maxcols-padded-8 flush-right"
                  options={lib.options.as_boolean(
                    "Hide Unselected Companies",
                    "Show Unselected Companies"
                  )}
                  value={showUnselected}
                  updateAction={toggler(unselected_key)}
                />
              </Field>
              <Field className="maxcols-4">
                <Select
                  name="show-inactive"
                  className="maxcols-padded-4"
                  options={lib.options.as_boolean(
                    "Hide Inactive",
                    "Show Inactive"
                  )}
                  value={showInactive}
                  updateAction={toggler(inactive_key)}
                />
              </Field>
            </Field>
          </FieldGroup>
          <FieldGroup className="maxcols-22">
            <Field className="maxcols-22">
              <TerritoryDetailList
                id={id}
                structure={structure}
                subFields={subFields}
                path={["companies"]}
                territory={value}
                updater={updater}
                errors={errors}
                toggles={toggles}
                toggleAction={toggleAction}
                showInactive={showInactive}
                showUnselected={showUnselected}
              />
            </Field>
          </FieldGroup>
          <br />
          {lib.coalesce(value.history, []).length > 0 ? (
            <FieldGroup className="maxcols-22">
              <legend className="item">
                <Anchor
                  className="menu-item.link to-left"
                  action={toggler("show territory history")}
                >
                  Territory History <AccordionDisplay open={show_history} />
                </Anchor>
              </legend>
              <Accordion open={show_history} className="maxcols-22">
                <TerritoryHistory
                  id={id + "-history"}
                  history={value.history}
                />
              </Accordion>
            </FieldGroup>
          ) : (
            <span />
          )}
        </Accordion>
      </div>
    );
  },
});

const TimeStamp = require("./TimeStamp.jsx");
const TimeWithZone = require("./TimeWithZone.jsx");

module.exports = ({
    time,
    timestamp,
    date,
    className,
    showOnBlank,
    formatter,
    showZone
}) => {
    module.exports.displayName = "Time";

    if (date !== undefined) {
        if (typeof date !== "string") {
            return (
                <time className={className} title={`${date}`}>
                    <i>Invalid</i>
                </time>
            );
        }
        if (date === "") {
            return (
                <time dateTime="" className={className}>
                    &mdash;
                </time>
            );
        }

        const {to_iso, from_iso, format} = lib.date;
        const use_formatter = lib.coalesce(formatter, format.plain.date);
        return (
            <time dateTime={date} className={className}>
                {use_formatter(from_iso(date))}
            </time>
        );
    }

    const the_time = lib.coalesce(time, timestamp);
    const the_type = typeof the_time;
    if (the_type === "number") {
        return (
            <TimeStamp
                timestamp={the_time}
                className={className}
                formatter={formatter}
            />
        );
    }
    if (the_type === "object") {
        return (
            <TimeWithZone
                time={the_time}
                className={className}
                formatter={formatter}
                showZone={showZone}
            />
        );
    }
    if (the_type === "undefined" || the_type === "string") {
        const classes = lib.handle_classes(className, "time");
        return showOnBlank !== undefined ? (
            <span className={classes}>{showOnBlank}</span>
        ) : (
            <span className={classes}>&mdash;</span>
        );
    }
    return <span />;
};

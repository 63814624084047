const FieldGroup = require("./../../FieldGroup.jsx");

const PlacementEntry = require("./PlacementEntry.jsx");

const zero_cost = (entry) => {
  const { costs } = entry;
  const Fields = [
    "budget",
    "ad_fee",
    "placement_fee",
    "cancellation_fee",
    "other_fee",
    "final_cost",
  ];
  return !Fields.some((f) => lib.money.to_cents(costs[f]) !== 0);
};

module.exports = ({
  placements,
  className,
  updateAction,
  showZeroPlacements = true,
  showOnlyIf = true,
}) => {
  module.exports.displayName = "PlacementsList";
  if (!showOnlyIf) {
    return <span />;
  }
  const updater = lib.updaters.wrapping({
    object: placements,
    action: updateAction,
  });

  return (
    <FieldGroup className={className}>
      {placements.map((entry, i) => {
        const key = `series-place-${entry.id}`;
        if (!showZeroPlacements && zero_cost(entry)) {
          return <span key={key} />;
        }
        return (
          <PlacementEntry entry={entry} key={key} updateAction={updater(i)} />
        );
      })}
    </FieldGroup>
  );
};

const Time = require("./Time.jsx");

const AttachmentDelete = ({ active, id, updateAction }) => {
  const archive_toggle_handler = lib.comp.handle_if_extant(() => {
    updateAction(!active);
  });
  if (active) {
    return (
      <button
        className="warning-button little-button"
        onClick={archive_toggle_handler}
      >
        {id === undefined ? "Remove" : "Archive"}
      </button>
    );
  }
  return (
    <button className="little-button" onClick={archive_toggle_handler}>
      Cancel
    </button>
  );
};

module.exports = ({
  attachment, //.......the actual Attachent API data
  baseURL, //..........where in the API the link should point at
  text = "", //..........text to show instead of original file name
  newTab = true, //......whether the link tries to open in a new tab
  showDate = true, //....whether to show the recorded date
  updateAction, //......action to allow archiving/canceling archiving
}) => {
  module.exports.displayName = "AttachmentLink";

  const { id, item, file, active } = attachment ?? {};
  const { hash } = file ?? {};

  const name = file?.name.toString().replace("." + file.ext, "");
  const type_hint = file?.ext.toString().toUpperCase();
  const label_text = (text ?? "") === "" ? name : text;

  const item_text = (
    <span>
      {label_text} ({type_hint})
      {showDate && id !== undefined ? (
        <span>
          &mdash;
          <Time timestamp={file.uploaded} />
        </span>
      ) : (
        <span />
      )}
    </span>
  );

  const link_class = "item.link" + (active ? "" : " stricken");
  const link =
    id === undefined
      ? undefined
      : baseURL + `attachments/index.cfm?item=${item}&hash=${hash}`;

  // rel="noopener" avoids a phishing vulnerability
  // SEE: https://dev.to/ben/the-targetblank-vulnerability-by-example
  return (
    <div className="flex-this">
      {id === undefined ? (
        <div className={link_class.replace("item.link", "item.stub")}>
          {item_text}
        </div>
      ) : (
        <a
          className={link_class}
          href={link}
          rel="noopener"
          target={newTab ? "_blank" : undefined}
        >
          {item_text}
        </a>
      )}
      {updateAction === undefined ? (
        <span />
      ) : (
        <div className="item.stub maxcols-3 no-pad-left">
          <AttachmentDelete
            active={attachment?.active}
            id={id}
            updateAction={updateAction.nest("active").act}
          />
        </div>
      )}
    </div>
  );
};
